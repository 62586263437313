import React from "react";

const Input = props => {
  return (
    <div className={"form-group " + (props.className ? props.className : '')}>
      <label htmlFor={props.name} className="form-label">
        {props.title}
      </label>
      <input
        className={"form-control " + (props.className ? props.className : '')}
        id={props.name}
        name={props.name}
        type={props.type}
        value={props.value}
        onChange={props.handlechange}
        placeholder={props.placeholder}
      />
    </div>
  );
};

export default Input;
