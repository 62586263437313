import React, { Component } from "react";

import { userService } from '../auth/auth.js';

import Input from "../components/Input";
import Select from "../components/Select";
import Button from "../components/Button";

const textObject = window.textObject;
const options = window.options;

class FormContainer extends Component {
  constructor(props) {
    super(props);
    let locale = 'en';
    if (props.language) {
      locale = textObject[props.language].locale;
    }

    this.state = {
      login: localStorage.getItem('login'),
      language: props.language,
      newUser: {
        first_name: props.userData.first_name || "",
        middle_name: props.userData.middle_name || "",
        last_name: props.userData.last_name || "",
        email: props.userData.email || "",
        location: props.userData.location || "",
        location_type: props.userData.location_type || "",
        country: props.userData.country || "",
        other_country: props.userData.other_country || "",
        canada_isBC: props.userData.canada_isBC || "",
        main_contact: props.userData.main_contact || "",
        business_address: props.userData.business_address || "",
        zip_code: props.userData.zip_code || "",
        mobile_number: props.userData.mobile_number || "",
        state: props.userData.state || "",
        group: props.userData.group || "",
        group_field: props.userData.group_field || "",
        city: props.userData.city || "",
        postcode: props.userData.postcode || "",
        housenumber: props.userData.housenumber || "",
        business_name: props.userData.business_name || "",
        job_title: props.userData.job_title || "",
        carry: props.userData.carry || "",
        proclub: props.userData.proclub || "",
        marketing_subscription: props.userData.marketing_subscription || "",
        locale: props.userData.locale || locale,
        db_id: props.userData.db_id || "",
        register_timestamp: props.userData.register_timestamp || ""
      },
      host: props.host,
      verify: props.verify,
      showError: false,

      languageOptions: Object.keys(textObject)

    };

    this.gettext = props.gettext;
    this.handleLanguage = this.handleLanguage.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleFormVerify = this.handleFormVerify.bind(this);
  }

  /* This lifecycle hook gets executed when the component mounts */

  handleLanguage(e) {
    let language = e.target.value;
    let {newUser} = this.state;
    if (!this.state.verify) {
      newUser.locale = textObject[language].locale;
    }
    this.setState({
      language: language,
      newUser: newUser
    });
  }

  handleInput(e) {
    let newUser = this.state.newUser;
    newUser[e.target.name] = e.target.value;
    if (e.target.name === "location") {
      if (e.target.value === "US") {
        newUser.country = "US";
      } else {
        newUser.country = "";
        newUser.state = "";
        newUser.proclub = "";
        newUser.marketing_subscription = "";
        newUser.business_address = "";
        newUser.zip_code = "";
        newUser.mobile_number = "";
      }
    } else if (e.target.name === "state") {
      newUser.group_field = "";
    } else if (e.target.name === "canada_isBC") {
      var option = e.target.value === "Yes" ? 0 : 1;
      newUser.group_field = options.countries["Canada"][option];
    }
    this.setState({newUser: newUser});
  }

  validateUser() {
    let valid = true;
    const {newUser} = this.state;
    for (var key in newUser) {
      let val = newUser[key];
      if ((newUser.country !== 'US' && key in {state:1, proclub:1, marketing_subscription:1, business_address:1, zip_code:1, mobile_number:1})
          || (newUser.group_field && !newUser.group && key === 'group')
          || (newUser.country !== 'Other' && key === 'other_country')
          || (key === 'middle_name')
          || (newUser.country !== 'Netherlands' && key in {postcode:1, housenumber:1})
          || (newUser.country !== 'Canada' && key === 'canada_isBC')
          || (newUser.country !== 'United Kingdom / Ireland' && key === 'main_contact')) {
            continue;
      }
      if (!val.trim() && key !== 'db_id' && key !== 'register_timestamp') {
        console.log("fail validation: ", key, val);
        valid = false;
      }
    }
    if (this.badEmail()) {
      valid = false;
    }
    return valid;
  }

  handleFormSubmit(e) {
    e.preventDefault();
    if (!this.validateUser()) {
      this.setState({ showError: true });
      return;
    }

    const host = (process.env.NODE_ENV !== "development") ? '' : 'http://register:2717';
    this.handleAction(host, '/api/education', this.props.isSubmitted.bind(null, this.state.language));
  }

  handleFormVerify(action, e) {
    e.preventDefault();

    const host = (process.env.NODE_ENV !== "development") ? '' : 'http://verify:2717';
    this.handleAction(host, '/api/education/' + action, this.props.userVerified.bind(null));
  }

  prepData() {
    let newUser = this.state.newUser;
    if (newUser.group_field === "HydroPeptide Account Executive") {
      for (var region in options.regions) {
        if (newUser.state in options.regions[region]) {
          newUser.group = region;
          break;
        }
      }
    } else {
      newUser.group = newUser.group_field;
    }
    newUser.email = newUser.email.toLowerCase();
    for (let key in newUser) {
      if (typeof newUser[key] === "string") {
        newUser[key] = newUser[key].trim();
      }
    }
    return newUser;
  }

  handleAction(host, url, Callback) {
    if (this.state.verify && !this.state.login) {
      console.log("can't run because no auth");
      return null;
    }

    const newUser = this.prepData();

    let request = {};
    request[newUser.db_id ? newUser.db_id : newUser.email] = newUser;

    // Submit form with ajax
    let opts = {
      method: "POST",
      body: JSON.stringify(request),
      headers: {"Content-Type": "application/json"}
    };
    userService.request(host + url, opts).then(response => {
        console.log("Successful", response);
        Callback();
    }).catch(response => {
        console.error("error", response);
    });
  }

  getStates() {
    let states = [];
    for (var region in options.regions) {
      states = states.concat(Object.keys(options.regions[region]));
    }
    states.sort()
    return states;
  }

  getGroupOptions() {
    let opts = [];
    if (this.state.newUser.state && this.state.newUser.location !== "International") {
      for (var region in options.regions) {
        if (this.state.newUser.state in options.regions[region]) {
          opts = options.regions[region][this.state.newUser.state];
          break;
        }
      }
    } else if (this.state.newUser.country) {
      if (this.state.newUser.country in options.countries) {
        opts = options.countries[this.state.newUser.country];
      }
    }
    if (opts.length === 1) {
      let newUser = this.state.newUser;
      newUser.group_field = opts[0];
    }
    return opts;
  }

  getLocationTypes() {
    let opts = [];
    if (this.state.newUser.country && this.state.newUser.country in options.location_types) {
      opts = options.location_types[this.state.newUser.country];
    } else if (this.state.newUser.location) {
      opts = this.getOptionsLocalized(options.locations[this.state.newUser.location]);
    }
    return opts;
  }

  getOptionsLocalized(options) {
    let ret_opts = [];
    if (options) {
      options.forEach((option) => {
        ret_opts.push([option, option, this.gettext(option)]);
      });
    }

    return ret_opts;
  }

  getSubmitButtons() {
    if (this.state.verify) {
      return (
        <div>
        <Button
            action={this.handleFormVerify.bind(null, 'approve')}
            type={"primary"}
            title={this.gettext("Approve")}
            style={buttonStyle}
            value={"approve"}
          />{" "}
          <Button
            action={this.handleFormVerify.bind(null, 'reject')}
            type={"primary"}
            title={this.gettext("Reject")}
            style={buttonStyle}
            value={"reject"}
          />{" "}
          <Button
            action={this.handleFormVerify.bind(null, 'delete')}
            type={"primary"}
            title={this.gettext("Delete")}
            style={buttonStyle}
            value={"delete"}
          />{" "}
        </div>
      );
    } else {
      return (
        <div className={this.state.showError ? "is-invalid": ""}>
          <Button
            action={this.handleFormSubmit}
            type={"primary"}
            title={this.gettext("Submit")}
            style={buttonStyle}
          />{" "}
          { this.state.showError &&
            <label className="text-danger">{this.gettext("All fields are required. Please fill out the remaining fields.")}</label>
          }
        </div>
      );
    }
  }

  badEmail() {
    return (this.state.newUser.email
        && !/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(this.state.newUser.email.toLowerCase()));
  }

  badEmailLabel() {
    if (this.badEmail()) {
      return (
        <label className="text-danger">{this.gettext("Not a valid email")}</label>
      );
    }
  }

  showErrors(val, className) {
    let classNames = [];
    classNames.push(className);
    if (this.state.showError && !val.trim()) {
      classNames.push('is-invalid');
    }
    return classNames.join(' ');
  }

  render() {
    return (
      <form
        className="container-fluid"
        onSubmit={this.handleFormSubmit}>

        {/* Language Selection */}
        <Select
          title={"Language"}
          name={"language"}
          options={this.state.languageOptions}
          value={this.state.language}
          placeholder={"Select Language"}
          handlechange={this.handleLanguage}
        />{" "}

        <div className={this.state.language ? "": "d-none"}>
          <h1 className="App-heading">{this.gettext("Thank you for your interest in the HydroPeptide Digital Education Platform. Please answer a few questions below to begin the registration process.")}</h1>
          <hr/>
          <h1 className="App-title">{this.gettext("Registration")}</h1>
          {/* Name of the user */}
          <Input
            className={this.showErrors(this.state.newUser.first_name)}
            type={"text"}
            title={this.gettext("First Name")}
            name={"first_name"}
            value={this.state.newUser.first_name}
            placeholder={this.gettext("Enter your first name")}
            handlechange={this.handleInput}
          />{" "}
          <Input
            type={"text"}
            title={this.gettext("Middle Name")}
            name={"middle_name"}
            value={this.state.newUser.middle_name}
            placeholder={this.gettext("Enter your middle name (optional)")}
            handlechange={this.handleInput}
          />{" "}
          <Input
            className={this.showErrors(this.state.newUser.last_name)}
            type={"text"}
            title={this.gettext("Last Name")}
            name={"last_name"}
            value={this.state.newUser.last_name}
            placeholder={this.gettext("Enter your last name")}
            handlechange={this.handleInput}
          />{" "}
          <Input
            className={this.showErrors(this.state.newUser.email)}
            type={"text"}
            title={this.gettext("Email")}
            name={"email"}
            value={this.state.newUser.email}
            placeholder={this.gettext("Enter your email")}
            handlechange={this.handleInput}
          />{" "}
          {this.badEmailLabel()}
          <Select
            className={this.showErrors(this.state.newUser.location)}
            title={this.gettext("Location")}
            name={"location"}
            options={this.getOptionsLocalized(Object.keys(options.locations))}
            value={this.state.newUser.location}
            placeholder={this.gettext("Select Location")}
            handlechange={this.handleInput}
          />{" "}
          <Input
            className={this.showErrors(this.state.newUser.business_address, this.state.newUser.location === "US" ? "": "d-none")}
            type={"text"}
            title={this.gettext("Business Shipping Address")}
            name={"business_address"}
            value={this.state.newUser.business_address}
            placeholder={this.gettext("Enter your Business Shipping Address")}
            handlechange={this.handleInput}
          />{" "}
          <Input
            className={this.showErrors(this.state.newUser.zip_code, this.state.newUser.location === "US" ? "": "d-none")}
            type={"text"}
            title={this.gettext("ZIP Code")}
            name={"zip_code"}
            value={this.state.newUser.zip_code}
            placeholder={this.gettext("Enter your ZIP Code")}
            handlechange={this.handleInput}
          />{" "}
          <Input
            className={this.showErrors(this.state.newUser.mobile_number, this.state.newUser.location === "US" ? "": "d-none")}
            type={"text"}
            title={this.gettext("Mobile Number")}
            name={"mobile_number"}
            value={this.state.newUser.mobile_number}
            placeholder={this.gettext("Enter your Mobile Number")}
            handlechange={this.handleInput}
          />{" "}
          <Select
            className={this.showErrors(this.state.newUser.country, this.state.newUser.location === "International" ? "": "d-none")}
            title={this.gettext("Country")}
            name={"country"}
            options={this.getOptionsLocalized(Object.keys(options.countries))}
            value={this.state.newUser.country}
            placeholder={this.gettext("Select Country")}
            handlechange={this.handleInput}
          />{" "}
          <Input
            className={this.showErrors(this.state.newUser.other_country, this.state.newUser.country === "Other" ? "" : "d-none")}
            type={"text"}
            title={this.gettext("Identify Country")}
            name={"other_country"}
            value={this.state.newUser.other_country}
            placeholder={this.gettext("Enter your Country")}
            handlechange={this.handleInput}
          />{" "}
          <Select
            className={this.showErrors(this.state.newUser.canada_isBC, this.state.newUser.country === "Canada" ? "" : "d-none")}
            title={this.gettext("Are you in British Columbia?")}
            name={"canada_isBC"}
            options={this.getOptionsLocalized(["Yes", "No"])}
            value={this.state.newUser.canada_isBC}
            placeholder={this.gettext("Select Response")}
            handlechange={this.handleInput}
          />{" "}
          <Select
            className={this.showErrors(this.state.newUser.main_contact, this.state.newUser.country === "United Kingdom / Ireland" ? "" : "d-none")}
            title={this.gettext("Select your Main Contact")}
            name={"main_contact"}
            options={["HydroPeptide Account Executive", "Wigmore Medical"]}
            value={this.state.newUser.main_contact}
            placeholder={this.gettext("Select Response")}
            handlechange={this.handleInput}
          />{" "}
          <Select
            className={this.showErrors(this.state.newUser.state, this.state.newUser.location === "US" ? "": "d-none")}
            title={this.gettext("State")}
            name={"state"}
            options={this.getStates()}
            value={this.state.newUser.state}
            placeholder={this.gettext("Select State")}
            handlechange={this.handleInput}
          />{" "}
          <Select
            className={
              this.showErrors(this.state.newUser.group_field, ((!this.state.newUser.state && !this.state.newUser.country)
                                                                || this.state.newUser.location === "International"
                                                                || this.getGroupOptions().length < 2 ? "d-none" : ""))
            }
            title={this.gettext("Who is your main HydroPeptide contact?")}
            name={"group_field"}
            options={this.getGroupOptions()}
            value={this.state.newUser.group_field}
            placeholder={this.gettext("Select Contact")}
            handlechange={this.handleInput}
          />{" "}
          <Input
            className={this.showErrors(this.state.newUser.city, this.state.newUser.location ? "": "d-none")}
            type={"text"}
            title={this.gettext("City")}
            name={"city"}
            value={this.state.newUser.city}
            placeholder={this.gettext("Enter your City")}
            handlechange={this.handleInput}
          />{" "}
          <Input
            className={this.showErrors(this.state.newUser.postcode, this.state.newUser.country === "Netherlands" ? "": "d-none")}
            type={"text"}
            title={this.gettext("Postal Code")}
            name={"postcode"}
            value={this.state.newUser.postcode}
            placeholder={this.gettext("Enter your Postal Code")}
            handlechange={this.handleInput}
          />{" "}
          <Input
            className={this.showErrors(this.state.newUser.housenumber, this.state.newUser.country === "Netherlands" ? "": "d-none")}
            type={"text"}
            title={this.gettext("House Number")}
            name={"housenumber"}
            value={this.state.newUser.housenumber}
            placeholder={this.gettext("Enter your House Number")}
            handlechange={this.handleInput}
          />{" "}
          <Input
            className={this.showErrors(this.state.newUser.business_name)}
            type={"text"}
            title={this.gettext("Business Name")}
            name={"business_name"}
            value={this.state.newUser.business_name}
            placeholder={this.gettext("Enter the Business Name")}
            handlechange={this.handleInput}
          />{" "}
          <Select
            className={this.showErrors(this.state.newUser.location_type, !this.state.newUser.location ? "d-none" : "")}
            title={this.gettext("Location Type")}
            name={"location_type"}
            options={this.getLocationTypes()}
            value={this.state.newUser.location_type}
            placeholder={this.gettext("Select Location Type")}
            handlechange={this.handleInput}
          />{" "}
          <Input
            className={this.showErrors(this.state.newUser.job_title)}
            type={"text"}
            title={this.gettext("Job Title")}
            name={"job_title"}
            value={this.state.newUser.job_title}
            placeholder={this.gettext("Enter your Job Title")}
            handlechange={this.handleInput}
          />{" "}
          <Select
            className={this.showErrors(this.state.newUser.carry)}
            title={this.gettext("Do You Currently Carry HydroPeptide?")}
            name={"carry"}
            options={this.getOptionsLocalized(["Yes", "No"])}
            value={this.state.newUser.carry}
            placeholder={this.gettext("Select Response")}
            handlechange={this.handleInput}
          />{" "}
          <Select
            className={this.showErrors(this.state.newUser.proclub, this.state.newUser.location === "US" ? "": "d-none")}
            title={this.gettext("Are you a registered member of Proclub on HydroPeptide.com?")}
            name={"proclub"}
            options={this.getOptionsLocalized(["Yes", "No"])}
            value={this.state.newUser.proclub}
            placeholder={this.gettext("Select Response")}
            handlechange={this.handleInput}
          />{" "}
          <Select
            className={this.showErrors(this.state.newUser.marketing_subscription, this.state.newUser.location === "US" ? "": "d-none")}
            title={this.gettext("By choosing \"Yes\", you agree to receive recurring promotional and personalized marketing email messages from HydroPeptide, including updates and opportunities for advanced education, at the email used when signing up. You may unsubscribe at any time.")}
            name={"marketing_subscription"}
            options={this.getOptionsLocalized(["Yes", "No"])}
            value={this.state.newUser.marketing_subscription}
            placeholder={this.gettext("Select Response")}
            handlechange={this.handleInput}
          />{" "}
          {/*Submit */}
          {this.getSubmitButtons()}
        </div>
      </form>
    );
  }
}

const buttonStyle = {
  margin: "10px 10px 10px 10px"
};

export default FormContainer;
