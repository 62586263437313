import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";

import { userService } from '../auth/auth.js';

import Moment from 'react-moment';
import Button from "../components/Button";

class ListContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      archive: []
    };
    this.getList();
    this.getArchive();
    this.verifyUser = props.verifyUser;
    this.handleVerify = this.handleVerify.bind(this);
    this.getExtract = this.getExtract.bind(this);
  }

  getList() {
    this.getData('/api/education', 'data');
  }

  getArchive() {
    this.getData('/api/education/archive', 'archive');
  }

  getExtractButton() {
    // let login = JSON.parse(localStorage.getItem('login'));
    // if (login && login.user.toLowerCase() === 'admin') {
    return (
      <div>
      <Button
          action={this.getExtract}
          type={"primary"}
          title={"Archive CSV"}
          style={buttonStyle}
          value={"archive"}
        />{" "}
      </div>
    );
    // }
  }

  getExtract() {
    /*
     * Can't use Basic auth since it has file attachment
     * Instead, store basic auth as temporary cookie for use by server
     * Then delete cookie with a 500 millisecond window for the connection to be made
     */
    let login = JSON.parse(localStorage.getItem('login'));
    document.cookie = "authdata='" + login.authdata + "'";

    const host = (process.env.NODE_ENV !== "development") ? '' : 'http://verify:2717';
    window.open(host + '/api/education/extract', '_blank');

    window.setTimeout(function() { document.cookie = "authdata="; }, 30000);
  }

  getData(url, key) {
    if (!localStorage.getItem('login')) {
      console.log("can't run because no auth");
      return null;
    }
    const host = (process.env.NODE_ENV !== "development") ? '' : 'http://verify:2717';
    let opts = {
      method: "GET",
      headers: {"Content-Type": "application/json"}
    };
    userService.request(host + url, opts).then(data => {
      let list_data = [];
      data.forEach(function (db_item) {
        Object.keys(db_item).forEach(function(db_id) {
          list_data.push(Object.assign(db_item[db_id], {db_id: db_id}));
        });
      });
      console.log("Successful", list_data);
      this.setState(
        prevState => ({
          ...prevState,
          [key]: list_data
        })
      );
    }).catch(response => {
        console.error("error", response);
    });
  }

  handleVerify(data) {
    this.verifyUser(data);
  }

  render() {
    const {data, archive} = this.state;
    return (
      <div>
        <h3 className="text-center">Users to Verify</h3>
        <ReactTable
          data={data}
          columns={[
            {
              columns: [
                {
                  Header: "Timestamp",
                  id: 'register_timestamp',
                  accessor: 'register_timestamp',
                  Cell: c => {
                    return (
                      <Moment>{c.value*1000}</Moment>
                    )
                  }
                },
                {
                  Header: "First Name",
                  accessor: "first_name"
                },
                {
                  Header: "Last Name",
                  accessor: "last_name"
                },
                {
                  Header: "Email",
                  accessor: "email"
                },
                {
                  Header: '',
                  Cell: row => (
                    <div>
                      <button onClick={() => this.handleVerify(row.original)}>Verify</button>
                    </div>
                  ),
                  width: 65
                }
              ]
            }
          ]}
          defaultPageSize={10}
          className="-striped -highlight"
          defaultSortDesc={true}
          defaultSorted={[{id: "register_timestamp"}]}
        />
        <h3 className="text-center">Verified Users</h3>
        {this.getExtractButton()}
        <ReactTable
          data={archive}
          columns={[
            {
              columns: [
                {
                  Header: "Register Time",
                  id: 'register_timestamp',
                  accessor: 'register_timestamp',
                  Cell: c => {
                    return (
                      <Moment>{c.value*1000}</Moment>
                    )
                  }
                },
                {
                  Header: "Verify Time",
                  id: 'archive_timestamp',
                  accessor: 'archive_timestamp',
                  Cell: c => {
                    return (
                      <Moment>{c.value*1000}</Moment>
                    )
                  }
                },
                {
                  Header: "Action",
                  accessor: "action"
                },
                {
                  Header: "First Name",
                  accessor: "first_name"
                },
                {
                  Header: "Last Name",
                  accessor: "last_name"
                },
                {
                  Header: "Email",
                  accessor: "email"
                }
              ]
            }
          ]}
          defaultPageSize={10}
          className="-striped -highlight"
          defaultSortDesc={true}
          defaultSorted={[{id: "register_timestamp"}]}
        />
      </div>
    );
  }
}

const buttonStyle = {
  margin: "10px 10px 10px 10px"
};

export default ListContainer;
