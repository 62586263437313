import React, { Component } from "react";

class ThankyouContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      language: props.language
    };
    this.gettext = props.gettext;
  }

  render() {
    return (
      <h1 className="App-heading container-fluid">
        {this.gettext("Thank you for registering for the HydroPeptide Digital Education Platform. Your registration is currently undergoing an approval process. You will receive an email within 24 business hours allowing you to create a password and enter the platform.")}
      </h1>
    );
  }
}

export default ThankyouContainer;
