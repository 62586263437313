import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { userService } from './auth/auth.js';

import logo from './logo_cropped.jpg';
import './App.css';
import FormContainer from "./containers/FormContainer";
import ThankyouContainer from "./containers/ThankyouContainer";
import ListContainer from "./containers/ListContainer";

const textObject = window.textObject;

class App extends Component {
  constructor(props) {
    super(props);

    if (process.env.NODE_ENV !== "development") {
      delete textObject.Test;
    }
    const language = Object.keys(textObject).length === 1 ? Object.keys(textObject)[0] : "";

    this.state = {
      login: localStorage.getItem('login'),
      register: /register/.test(window.location.hostname),
      verify: /verify/.test(window.location.hostname),
      submitted: false,
      language: language,
      list: true,
      showError: false,
      userData: {}
    };

    if ((this.state.register && this.state.verify) || (!this.state.register && !this.state.verify)) {
      this.state.register = !this.state.register;
    }

    this.isSubmitted = this.isSubmitted.bind(this);
    this.verifyUser = this.verifyUser.bind(this);
    this.userVerified = this.userVerified.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  isSubmitted(language) {
    this.setState({
      submitted: true,
      language: language
    });
  }

  verifyUser(userData) {
    this.setState({
      list: false,
      userData: userData
    });
  }

  userVerified() {
    this.setState({
      list: true,
      userData: {}
    });
  }

  handleLogin(e) {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    const host = (process.env.NODE_ENV !== "development") ? '' : 'http://verify:2717';
    userService.login(host, form.Username.value, form.Password.value).then(function() {
      window.location.reload();
    }).catch(e => {
      console.error("fail", e);
      this.setState({ showError: true });
    });
  }

  gettext(text) {
    let rtnText = text;
    let language = this.state.language;
    if (!language) {
      return rtnText;
    }
    if (!(language in textObject)) {
      console.error("language not found", language);
    } else if (!(text in textObject[language])) {
      console.error("text not found", text);
    } else {
      rtnText = textObject[language][text];
    }
    return rtnText;
  }


  GetBody() {
    if (this.state.register) {
      if (this.state.submitted) {
        return (
          <ThankyouContainer
            gettext={this.gettext}
            language={this.state.language}
          />
        )
      }
      return (
        <FormContainer
          isSubmitted={this.isSubmitted}
          gettext={this.gettext}
          language={this.state.language}
          userData={this.state.userData}
        />
      );
    } else if (this.state.verify) {
      if (this.state.list) {
        return (
          <ListContainer
            verifyUser={this.verifyUser}
          />
        )
      } else {
        return (
          <FormContainer
            verify={this.state.verify}
            userData={this.state.userData}
            gettext={this.gettext}
            language={"English"}
            userVerified={this.userVerified}
          />
        )
      }
    }
  }

  getLogin() {
    if (this.state.verify) {
      if (!this.state.login) {
        // popup login modal
        return (
          <Modal show={!this.state.login}>
            <Modal.Header closeButton>
              <Modal.Title>Login</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onSubmit={e => this.handleLogin(e)}
              >
                <Form.Group controlId="Username">
                  <Form.Label>Username</Form.Label>
                  <Form.Control required type="username" placeholder="Enter username" />
                </Form.Group>

                <Form.Group controlId="Password">
                  <Form.Label>Password</Form.Label>
                  <Form.Control required type="password" placeholder="Password" />
                </Form.Group>
                <Button variant="primary" type="submit">
                  Login
                </Button>
                {" "}
                { this.state.showError &&
                  <label className="control-label text-danger">{this.gettext("Bad Credentials")}</label>
                }
              </Form>
            </Modal.Body>
          </Modal>
      );
      } else {
        return(
          <div className="login">
            <button
              className="btn btn-link"
              onClick={
                function() {
                  userService.logout();
                  window.location.reload();
                }
              }
            >
              Logout
            </button>
          </div>
        );
      }
    }
  }

  getColumns() {
    let cols = "App-form col-md-4 offset-md-4";
    if (this.state.verify && this.state.list) {
      cols = "App-form col-md-10 offset-md-1";
    }
    return cols;
  }

  render() {
    return (
      <div className="App">
        <header className="App-header col-md-4 offset-md-4">
          <img src={logo} className="App-logo" alt="logo" />
          {this.getLogin()}
        </header>
        <div className={this.getColumns()}>
          {this.GetBody()}
        </div>
      </div>
    );
  }
}

export default App;
