import React from "react";

const Select = props => {
  return (
    <div className={"form-group " + (props.className ? props.className : '')} >
      <label htmlFor={props.name}> {props.title} </label>
      <select
        id={props.name}
        name={props.name}
        value={props.value}
        onChange={props.handlechange}
        className={"form-control " + (props.className ? props.className : '')}
      >
        {props.options.length > 1 &&
          <option value="" disabled>
            {props.placeholder}
          </option>
        }
        {props.options.map(option => {
          let o = [option, option, option]
          if (typeof option === "object") {
            o = option;
          }
          return (
            <option key={o[0]} value={o[1]} label={o[2]}>
              {option}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default Select;
